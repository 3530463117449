<script setup>
const menuOpen = ref(false);

const route = useRoute();
const moduleSlug = computed(() => route.params.module);

const { progress, getOriginPath } = useProgressStorage();

const originPath = getOriginPath();

const currentTitle = computed(() => {
    const module = progress.value?.modules.find(entry => entry.slug === moduleSlug.value);
    const chapter = module?.chapters.find(entry => entry.slug === route.params.chapter);

    const chapterIndex = module?.chapters.findIndex(entry => entry.slug === route.params.chapter);

    if (route.name === 'module-module-end') {
        return 'End';
    }

    return chapter ? `${chapterIndex + 1}. ${chapter?.title}` : 'Welcome';
});
</script>

<template>
    <header class="the-module-navigation">
        <TheHamburgerMenu
            class="hamburger-menu--desktop-visible"
            :data-toggled="menuOpen"
            @click="menuOpen === true ? menuOpen = false : menuOpen = true"
        />
        <div class="the-module-navigation__title">
            <slot>{{ currentTitle }}</slot>
        </div>

        <div
            v-if="moduleSlug"
            class="the-module-navigation__actions"
        >
            <BaseModuleProgress>
                <template #default="{ moduleProgress }">
                    <BaseModuleProgressGraph :module-progress="moduleProgress(moduleSlug)" />
                </template>
            </BaseModuleProgress>
        </div>

        <div
            class="the-module-navigation__offscreen"
            :class="{
                'is-active': menuOpen === true
            }"
        >
            <BaseModuleProgressMenu
                class="the-module-navigation__menu"
                @close="menuOpen = false"
            />

            <BaseButton
                class="dn-button--wide"
                @click="menuOpen = false"
            >
                Resume
            </BaseButton>
            <BaseButton
                class="button--secondary dn-button--wide"
                element="a"
                :href="originPath"
            >
                Exit module
            </BaseButton>
        </div>

        <div
            class="the-module-navigation__overlay"
            :class="{
                'is-active': menuOpen === true
            }"
            @click="menuOpen = false"
        />
    </header>
</template>

<style lang=less>
.the-module-navigation {
    --navbar-height: 3.75rem;
    --color-text-dark: var(--color-primary-900);

    display: flex;
    align-items: center;
    justify-content: space-between;

    color: var(--color-primary-500);
    background: var(--color-primary-200);

    padding: 0 1rem;
    gap: 1rem;

    height: var(--navbar-height);

    .hamburger-menu__button {
        --hamburger-hover_background: var(--color-primary-500);
    }
}

.the-module-navigation__title {
    flex: 1 0 auto;
    font-family: Spartan, sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
    text-align: center;
    letter-spacing: -1px;
    color: var(--color-primary-700);
}

.the-module-navigation__offscreen {
    position: fixed;
    overflow-y: auto;

    display: flex;
    flex-direction: column;

    top: var(--navbar-height);
    left: 0;
    bottom: 0;
    transform: translateX(-100%);
    z-index: -1;
    width: 100%;

    padding: 1.5rem;
    background: var(--color-primary-200);
    height: calc(100% - var(--navbar-height));

    transition: transform var(--transition-timing-fast) var(--transition-timingFunction-in-out);

    &.is-active {
        transform: translateX(0);
    }

    @media @q-md-min {
        width: 40vw;
    }

    @media @q-xl-min {
        width: 30vw;
    }

    .base-button:hover {
        background-color: var(--color-primary-700);
    }

    .base-button.button--secondary {
        background-color: #fff;
        border-color: #fff;

        &:hover {
            background-color: rgba(255, 255, 255, .7);
            border-color: rgba(255, 255, 255, .7);
        }
    }
}

.the-module-navigation__menu {
    padding-bottom: 1rem;
    margin-bottom: auto;
}

.the-module-navigation__overlay {
    position: fixed;
    top: var(--navbar-height);
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -2;
    background: rgba(0, 0, 0, .75);
    transition: opacity var(--transition-timing-medium) var(--transition-timingFunction-in-out);
    opacity: 0;
    pointer-events: none;

    &.is-active {
        opacity: 1;
        pointer-events: auto;
    }
}
</style>
